import React, { useContext } from 'react'
import { ContextLang } from '../context/LanguageContext'

function Footer() {
  const {t} = useContext(ContextLang)
  return (
    <footer>
  <div className="tp-footer-area" data-bg-color="footer-bg-grey">
    
    <div className="tp-footer-bottom">
      <div className="container">
        <div className="tp-footer-bottom-wrapper">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="tp-footer-copyright">
                <p>© 2024 {t.allRightsReserved}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tp-footer-payment text-md-end">
                <p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

  )
}

export default Footer