import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProductsProvider } from './context/GlobalState';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SiteProvider } from './context/ContextSite';
import { OrderProvider } from './context/OrderContext';
import { LangProvider } from './context/LanguageContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <SiteProvider>
      <LangProvider>
        <ProductsProvider>
          <OrderProvider>
            <ToastContainer />
            <App />
          </OrderProvider>
        </ProductsProvider>
      </LangProvider>
    </SiteProvider>

  </BrowserRouter >
);

reportWebVitals();
