import React, { useContext } from 'react'
import Main from '../Layouts/Main'
import { Link } from 'react-router-dom'
import { ProductsContext } from '../context/GlobalState'
import WishProduct from '../components/WishProduct';
import EmptyCart from '../components/EmptyCart';
import { Skeleton } from '@mui/material';
import { ContextLang } from '../context/LanguageContext';

function Wishlist() {
    const { wish, loadingWishlist } = useContext(ProductsContext);
    const {t} = useContext(ContextLang);
    const storage = JSON.parse(localStorage.getItem('wishlist')) || [];
    return (
        <Main>
            <main>
                {/* breadcrumb area start */}
                <section className="breadcrumb__area include-bg pt-95 pb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="breadcrumb__content p-relative z-index-1">
                                    <h3 className="breadcrumb__title">{t.wishlist}</h3>
                                    <div className="breadcrumb__list">
                                        <span><Link to={'/'}>{t.home}</Link></span>
                                        <span>{t.wishlist}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* breadcrumb area end */}
                {/* cart area start */}
                {storage && storage.length > 0 ? (
                    <section className="tp-cart-area pb-120">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="tp-cart-list mb-45 mr-30">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th colSpan={2} className="tp-cart-header-product">{t.product}</th>
                                                    <th className="tp-cart-header-price">{t.price}</th>
                                                    <th className="tp-cart-header-quantity">{t.quantity}</th>
                                                    <th>{t.action}</th>
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {loadingWishlist ? (
                                                    Array.from({ length: storage.length }).map((_, index) => (
                                                        <tr>
                                                            {/* Product Image */}
                                                            <td className="tp-cart-img">
                                                                <div style={{ width: '100%', aspectRatio: 1 }}>
                                                                    <Skeleton variant="rectangular" animation="wave" width="70%" height="70%" />
                                                                </div>
                                                            </td>

                                                            {/* Product Title */}
                                                            <td className="tp-cart-title">
                                                                <Skeleton width="70%" />
                                                            </td>

                                                            {/* Product Price */}
                                                            <td className="tp-cart-price">
                                                                <Skeleton width="30%" />
                                                            </td>

                                                            {/* Product Quantity Control */}
                                                            <td className="tp-cart-quantity">
                                                                <Skeleton width="20%" />
                                                            </td>

                                                            {/* Remove Product Action */}
                                                            <td className="tp-cart-action">
                                                                <Skeleton width="10%" />
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    wish && wish.map((product, index) => (
                                                        <WishProduct key={index} product={product} />
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tp-cart-bottom">
                                        <div className="row align-items-end">
                                            <div className="col-xl-6 col-md-4">
                                                <div className="tp-cart-update">
                                                    <Link to="/cart" className="tp-cart-update-btn">{t.GoToCart}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : (
                    <EmptyCart />
                )}

                {/* cart area end */}
            </main>

        </Main>
    )
}

export default Wishlist