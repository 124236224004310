import React, { useContext } from 'react';
import { ProductsContext } from '../context/GlobalState';
import { Skeleton } from '@mui/material';
import Product from './Product';
import { ContextLang } from '../context/LanguageContext';

function TopProducts() {
    const { randomProducts, loadingR } = useContext(ProductsContext);
    const {t} = useContext(ContextLang);
    
    // Split the randomProducts into two groups: first 18 and next 18
    const firstGroup = randomProducts && randomProducts.slice(0, 18);
    const secondGroup = randomProducts && randomProducts.slice(18, 36);
    console.log(randomProducts)
    const choose = [t.bestSellers, t.newArrivals];

    return (
        <section className="tp-product-area pb-55">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-xl-5 col-lg-6 col-md-5">
                        <div className="tp-section-title-wrapper mb-40">
                            <h3 className="tp-section-title">{t.trendingProducts}
                                <svg width={114} height={35} viewBox="0 0 114 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M112 23.275C1.84952 -10.6834 -7.36586 1.48086 7.50443 32.9053" stroke="currentColor" strokeWidth={4} strokeMiterlimit="3.8637" strokeLinecap="round" />
                                </svg>
                            </h3>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6 col-md-7">
                        <div className="tp-product-tab tp-product-tab-border mb-45 tp-tab d-flex justify-content-md-end">
                            <ul className="nav nav-tabs justify-content-sm-end" id="productTab" role="tablist">
                                {loadingR ? (
                                    Array.from(new Array(2)).map((_, index) => (
                                        <li className="nav-item" role="presentation" key={index}>
                                            <Skeleton variant="text" width="60px" style={{ margin: '10px auto' }} />
                                        </li>
                                    ))
                                ) : (
                                    choose.map((category, index) => (
                                        <li className="nav-item" role="presentation" key={index}>
                                            <button 
                                                className={`nav-link ${index === 0 ? 'active' : ''}`} 
                                                id={`tab-${index}`} 
                                                data-bs-toggle="tab" 
                                                data-bs-target={`#tab-pane-${index}`} 
                                                type="button" 
                                                role="tab" 
                                                aria-controls={`tab-pane-${index}`} 
                                                aria-selected={index === 0}>
                                                {category}
                                                <span className="tp-product-tab-line">
                                                    <svg width={52} height={13} viewBox="0 0 52 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 8.97127C11.6061 -5.48521 33 3.99996 51 11.4635" stroke="currentColor" strokeWidth={2} strokeMiterlimit="3.8637" strokeLinecap="round" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </li>
                                    ))
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="tp-product-tab-content">
                            <div className="tab-content" id="myTabContent">
                                {loadingR ? (
                                    <div className="tab-pane fade show active" id="loading-tab-pane" role="tabpanel" tabIndex={0}>
                                        <div className="row">
                                            {Array.from({ length: 18 }).map((_, index) => (
                                                <div key={index} className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                    <div className="tp-product-item-2 mb-40">
                                                        <div style={{ width: '100%', aspectRatio: 1 }}>
                                                            <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />
                                                        </div>
                                                        <Skeleton width="30%" />
                                                        <Skeleton />
                                                        <Skeleton />
                                                        <Skeleton height={30} width="50%" />
                                                        <Skeleton width="20%" />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {/* First tab - Best Sellers */}
                                        <div className="tab-pane fade show active" id="tab-pane-0" role="tabpanel" aria-labelledby="tab-0" tabIndex={0}>
                                            <div className="row">
                                                {firstGroup && firstGroup.map((product, index) => (
                                                    <div key={index} className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                        <Product product={product} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {/* Second tab - New Arrivals */}
                                        <div className="tab-pane fade" id="tab-pane-1" role="tabpanel" aria-labelledby="tab-1" tabIndex={1}>
                                            <div className="row">
                                                {secondGroup && secondGroup.map((product, index) => (
                                                    <div key={index} className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                        <Product product={product} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TopProducts;