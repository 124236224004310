import React, { useContext, useState } from 'react'
import { ProductsContext } from '../context/GlobalState';
import { Link, useNavigate } from 'react-router-dom';
import slugify from 'react-slugify';
import BasketRight from '../components/BasketRight';
import MobMenu from '../components/MobMenu';
import EmailIcon from '@mui/icons-material/Email';
import { ContextSite } from '../context/ContextSite';
import BottomMenu from '../components/BottomMenu';
import { ContextLang } from '../context/LanguageContext';
function Header() {
    const { catParents, handleFilterChange, basket, wish } = useContext(ProductsContext);
    const { openMenu, openLang, setOpenLang, lang, handleLangChange, setOpenMenu, openBasket, setOpenBasket, openFilter, setOpenFilter, openSearch, setOpenSearch, openQuickView, setOpenQuickView } = useContext(ContextSite);
    const {t} = useContext(ContextLang)
    const addToCart = () => {
        setOpenBasket(!openBasket);
    };

    const openMobMenu = () => {
        setOpenMenu(!openMenu);
    }
    const chunkCategories = (categories, size) => {
        const chunks = [];
        for (let i = 0; i < categories.length; i += size) {
            chunks.push(categories.slice(i, i + size));
        }
        return chunks;
    };
    const navigate = useNavigate();

    const [search, setSearch] = useState();
    const history = JSON.parse(localStorage.getItem('history')) || [];
    const handleSubmit = (e) => {
        e.preventDefault(); 
        handleFilterChange('q', search); 
        navigate(`/catalog?q=${slugify(search)}`);
    };
    return (
        <>
            {/* back to top start */}
            <div className="back-to-top-wrapper">
                <button id="back_to_top" type="button" className="back-to-top-btn">
                    <svg width={12} height={7} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 6L6 1L1 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
            {/* back to top end */}
            {/* offcanvas area start */}
            <MobMenu open={openMenu} setOpen={setOpenMenu} />
            <div className={`body-overlay ${openBasket || openMenu || openFilter || openSearch || openQuickView ? 'opened' : ''} `}
                onClick={() => {
                    setOpenBasket(false);
                    setOpenMenu(false);
                    setOpenFilter(false);
                    setOpenSearch(false);
                    setOpenQuickView(false);
                }
                } />
            {/* offcanvas area end */}
            {/* mobile menu area start */}
            <BottomMenu />
            {/* mobile menu area end */}
            {/* search area start */}
            <section className={`tp-search-area ${openSearch ? 'opened' : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="tp-search-form">
                                <div className="tp-search-close text-center mb-20">
                                    <button className="tp-search-close-btn tp-search-close-btn" />
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="tp-search-input mb-10">
                                        <input
                                            type="text"
                                            placeholder="Search for Products..."
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                        <button type="submit"><i className="flaticon-search-1" /></button>
                                    </div>
                                    <div className="tp-search-category">
                                        {history && history.length > 0 && (
                                            <>
                                                <span>History : </span>
                                                {history.map((search, index) => (
                                                    <Link key={index} className='history me-2' to={`/catalog?q=${slugify(search.query)}`}>{search.query}</Link>
                                                ))}

                                            </>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* search area end */}
            {/* basket area start */}
            <BasketRight open={openBasket} setOpen={setOpenBasket} />
            {/* basket area end */}
            {/* header area start */}
            <header>
                <div className="tp-header-area tp-header-style-primary tp-header-height">
                    {/* header top start  */}
                    <div className="tp-header-top-2 p-relative z-index-11 tp-header-top-border d-none d-md-block">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="tp-header-info d-flex align-items-center">
                                        <div className="tp-header-info-item">
                                            <a href="mailto:info@elnaf.fi">
                                                <span>
                                                    <EmailIcon />
                                                </span> info@elnaf.fi
                                            </a>
                                        </div>
                                        <div className="tp-header-info-item">
                                            <a href="tel:+358-50-3139854">
                                                <span>
                                                    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.359 2.73916C1.59079 2.35465 2.86862 0.958795 3.7792 1.00093C4.05162 1.02426 4.29244 1.1883 4.4881 1.37943H4.48885C4.93737 1.81888 6.22423 3.47735 6.29648 3.8265C6.47483 4.68282 5.45362 5.17645 5.76593 6.03954C6.56213 7.98771 7.93402 9.35948 9.88313 10.1549C10.7455 10.4679 11.2392 9.44752 12.0956 9.62511C12.4448 9.6981 14.1042 10.9841 14.5429 11.4333V11.4333C14.7333 11.6282 14.8989 11.8698 14.9214 12.1422C14.9553 13.1016 13.4728 14.3966 13.1838 14.5621C12.502 15.0505 11.6125 15.0415 10.5281 14.5373C7.50206 13.2784 2.66618 8.53401 1.38384 5.39391C0.893174 4.31561 0.860062 3.42016 1.359 2.73916Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M9.84082 1.18318C12.5534 1.48434 14.6952 3.62393 15 6.3358" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M9.84082 3.77927C11.1378 4.03207 12.1511 5.04544 12.4039 6.34239" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span> +(358) 50 3139854
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="tp-header-top-right tp-header-top-black d-flex align-items-center justify-content-end">
                                        <div className="tp-header-top-menu d-flex align-items-center justify-content-end">
                                            <div className="tp-header-top-menu-item tp-header-lang">
                                                <span className="tp-header-lang-toggle" id="tp-header-lang-toggle" onClick={() => setOpenLang(!openLang)}>
                                                    {lang === 'en' ? 'English' : 'Finland'}
                                                </span>
                                                <ul className={openLang ? "tp-lang-list-open" : ''}>
                                                    <li>
                                                        <a href="#" onClick={() => handleLangChange('en')}>English</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" onClick={() => handleLangChange('fin')}>Finland</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* header bottom start */}
                    <div id="header-sticky" className="tp-header-bottom-2 tp-header-sticky">
                        <div className="container-fluid">
                            <div className="tp-mega-menu-wrapper p-relative">
                                <div className="row align-items-center">
                                    <div className="col-xl-2 col-lg-5 col-md-5 col-sm-4 col-6">
                                        <div className="logo">
                                            <Link to="/">
                                                <img src="/assets/img/logo/ElnafLogo.svg" alt="Elnaf.fi" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 d-none d-xl-block">
                                        <div className="main-menu menu-style-2">
                                            <nav className="tp-main-menu-content">
                                                <ul className='d-flex'>

                                                    {catParents && catParents.map((parent,index) => (
                                                        <li key={index} className="has-dropdown has-mega-menu ">
                                                            <a href="shop.html">{parent.parent}</a>
                                                            <ul className="tp-submenu tp-mega-menu mega-menu-style-2">
                                                                {chunkCategories(parent.cats, 10).map((categoryGroup, index) => (
                                                                    <li key={index} className="has-dropdown">
                                                                        <ul className="tp-submenu">
                                                                            {categoryGroup.map((cat) => (
                                                                                <li key={cat.cat_id}>
                                                                                    <Link to={`/catalog?category=${slugify(cat.category)}`}>{cat.category}</Link>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </li>
                                                    ))}


                                                </ul>
                                            </nav>
                                        </div>
                                       
                                    </div>
                                    <div className="col-xl-5 col-lg-7 col-md-7 col-sm-8 col-6">
                                        <div className="tp-header-bottom-right d-flex align-items-center justify-content-end pl-30">
                                            <div className="tp-header-search-2 d-none d-sm-block">
                                                <form onSubmit={handleSubmit}>
                                                    <input
                                                        type="text"
                                                        placeholder={`${t.search}...`}
                                                        value={search} 
                                                        onChange={(e) => setSearch(e.target.value)} 
                                                    />
                                                    <button type="submit">
                                                        <svg
                                                            width={20}
                                                            height={20}
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M18.9999 19L14.6499 14.65"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                </form>
                                            </div>
                                            <div className="tp-header-action d-flex align-items-center ml-30">
                                                <div className="tp-header-action-item d-none d-lg-block">
                                                    <Link to="/wishlist" className="tp-header-action-btn">
                                                        <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.239 18.8538C13.4096 17.5179 15.4289 15.9456 17.2607 14.1652C18.5486 12.8829 19.529 11.3198 20.1269 9.59539C21.2029 6.25031 19.9461 2.42083 16.4289 1.28752C14.5804 0.692435 12.5616 1.03255 11.0039 2.20148C9.44567 1.03398 7.42754 0.693978 5.57894 1.28752C2.06175 2.42083 0.795919 6.25031 1.87187 9.59539C2.46978 11.3198 3.45021 12.8829 4.73806 14.1652C6.56988 15.9456 8.58917 17.5179 10.7598 18.8538L10.9949 19L11.239 18.8538Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M7.26062 5.05302C6.19531 5.39332 5.43839 6.34973 5.3438 7.47501" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        {wish && wish.length > 0 && (
                                                            <span className="tp-header-action-badge">{wish.length}</span>
                                                        )}
                                                    </Link>
                                                </div>
                                                <div className="tp-header-action-item d-none d-lg-block">
                                                    <button onClick={addToCart} className="tp-header-action-btn cartmini-open-btn">
                                                        <svg width={21} height={22} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.48626 20.5H14.8341C17.9004 20.5 20.2528 19.3924 19.5847 14.9348L18.8066 8.89359C18.3947 6.66934 16.976 5.81808 15.7311 5.81808H5.55262C4.28946 5.81808 2.95308 6.73341 2.4771 8.89359L1.69907 14.9348C1.13157 18.889 3.4199 20.5 6.48626 20.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M6.34902 5.5984C6.34902 3.21232 8.28331 1.27803 10.6694 1.27803V1.27803C11.8184 1.27316 12.922 1.72619 13.7362 2.53695C14.5504 3.3477 15.0081 4.44939 15.0081 5.5984V5.5984" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M7.70365 10.1018H7.74942" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M13.5343 10.1018H13.5801" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        {basket && basket.length > 0 && (
                                                            <span className="tp-header-action-badge">{basket.length}</span>
                                                        )}
                                                    </button>
                                                </div>
                                                <div className="tp-header-action-item tp-header-hamburger mr-20 d-xl-none">
                                                    <button type="button" className="tp-offcanvas-open-btn" onClick={openMobMenu}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={30} height={16} viewBox="0 0 30 16">
                                                            <rect x={10} width={20} height={2} fill="currentColor" />
                                                            <rect x={5} y={7} width={25} height={2} fill="currentColor" />
                                                            <rect x={10} y={14} width={20} height={2} fill="currentColor" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* header area end */}


        </>
    )
}

export default Header