import { useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ProductsContext } from '../context/GlobalState';
import MuiPagination  from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const Pagination = () => {
    const { pageCount, page, handleFilterChange } = useContext(ProductsContext);
    return (
        <div className="tp-shop-pagination mt-20">
            <Stack spacing={2}>
                <MuiPagination 
                    count={pageCount}
                    page={page}
                    onChange={(event, value) => handleFilterChange('page', value)}
                    variant="outlined"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                />
            </Stack>
        </div>
    );
};

export default Pagination;