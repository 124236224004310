import React, { useContext, useEffect, useState } from 'react'
import { ProductsContext } from '../context/GlobalState'
import { Skeleton } from '@mui/material';
import slugify from 'react-slugify';
import { Link } from 'react-router-dom';
import { ContextLang } from '../context/LanguageContext';

function TopCategories() {
    const { categories, loadingCat } = useContext(ProductsContext);
    const {t} = useContext(ContextLang);
    const [randomCategories, setRandomCategories] = useState();
    useEffect(()=>{
        const randomCategoriess = categories
        .sort(() => 0.5 - Math.random())
        .slice(0, 5);
        setRandomCategories(randomCategoriess)
    },[categories])
    

    return (
        <section className="tp-product-category pt-60 pb-15">
            <div className="container">
                <div className="row row-cols-xl-5 row-cols-lg-5 row-cols-md-4">
                    {loadingCat ? (
                        // Show skeletons when loading
                        Array.from(new Array(5)).map((_, index) => (
                            <div className="col" key={index}>
                                <div className="tp-product-category-item text-center mb-40">
                                    <div style={{ width: '100%', aspectRatio: 1 }}>
                                        <Skeleton variant="circular" animation="wave" width="100%" height="100%" />
                                    </div>
                                    <Skeleton variant="text" width="60%" style={{ margin: '10px auto' }} />
                                    <Skeleton variant="text" width="40%" style={{ margin: '10px auto' }} />
                                </div>
                            </div>
                        ))
                    ) : (
                        // Show actual categories when loaded
                        randomCategories && randomCategories.map((category, index) => (
                            <div className="col" key={index}>
                                <div className="tp-product-category-item text-center mb-40">
                                    <div className="tp-product-category-thumb fix">
                                        <Link to={`/catalog?category=${slugify(category.name)}`} target='_blank' title={category.name}>
                                            <img src={category.icon} alt={category.name} />
                                        </Link>
                                    </div>
                                    <div className="tp-product-category-content">
                                        <h3 className="tp-product-category-title" title={category.name}>
                                            <Link to={`/catalog?category=${slugify(category.name)}`} target='_blank' title={category.name}>{category.name}</Link>
                                        </h3>
                                        <p>{category.p_count} {t.product}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </section>
    )
}

export default TopCategories