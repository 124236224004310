import React, { useContext, useEffect, useState } from 'react'
import { ProductsContext } from '../context/GlobalState'
import { Link, useParams } from 'react-router-dom';
import slugify from 'react-slugify';
import { Button, Chip, Skeleton, Slider } from '@mui/material';
import { ContextLang } from '../context/LanguageContext';

function Sidebar({type = 'mobile'}) {
    const { categories, loadingCat, brands, loadingBr, handleFilterChange, searchParams, minPrice, maxPrice, loading } = useContext(ProductsContext);
    const {t} = useContext(ContextLang);
    const tags = Array.from(searchParams.entries()).map(([key, value]) => ({ key, value }));
    const numericMinPrice = parseFloat(minPrice) || 0;
    const numericMaxPrice = parseFloat(maxPrice) || 1000;
    const [value, setValue] = useState([numericMinPrice, numericMaxPrice]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className={`tp-shop-sidebar mr-10 ${type}`}>
            {/* filter */}
            <div className="tp-shop-widget mb-35">
                {tags.map((param) => (
                    param.key !== 'page' &&
                    <Chip className='mb-4'
                        key={param.key}
                        label={`${param.value} ${param.key === 'minPrice' || param.key === 'maxPrice' ? '$' : ''}`}
                        variant="outlined"
                        onDelete={() => handleFilterChange(param.key)} // Funksiyanı burada bükün
                    />
                ))}
                <h3 className="tp-shop-widget-title no-border">{t.priceFilter}</h3>

                {!loading ? (
                    <>
                        <Slider
                            value={value}
                            onChange={handleChange}
                            valueLabelDisplay="auto"
                            min={numericMinPrice}
                            max={numericMaxPrice}
                            step={10}
                        />
                        <Button variant="contained" onClick={() => handleFilterChange('price', value)}>{t.filter}</Button>
                    </>
                ) :
                    <>
                        <Skeleton />
                        <Skeleton width="50%" height={40} />
                    </>
                }
            </div>

            {/* categories */}
            <div className="tp-shop-widget mb-50">
                <h3 className="tp-shop-widget-title">{t.categories}</h3>
                <div className="tp-shop-widget-content">
                    <div className="tp-shop-widget-categories">
                        <ul>
                            {loadingCat ? (
                                Array.from({ length: 15 }).map((_, index) => (
                                    <div key={index}>
                                        <Skeleton width="70%" />
                                        <Skeleton width="30%" />
                                    </div>
                                ))
                            ) : (
                                categories && categories.length > 0 ? (
                                    categories.map(category => (
                                        <li key={category.id}>
                                            <button onClick={() => handleFilterChange('category', slugify(category.name))}>
                                                {category.name} <span>{category.p_count}</span>
                                            </button>
                                        </li>
                                    ))
                                ) : (
                                    <li>{t.noCategoriesAvailable}</li>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="tp-shop-widget mb-50">
                <h3 className="tp-shop-widget-title">{t.brands}</h3>
                <div className="tp-shop-widget-content ">
                    <div className="tp-shop-widget-categories">
                        <ul>
                            {loadingBr ? (
                                Array.from({ length: 15 }).map((_, index) => (
                                    <div key={index}>
                                        <Skeleton width="70%" />
                                        <Skeleton width="30%" />
                                    </div>
                                ))
                            ) : (
                                brands && brands.length > 0 ? (
                                    brands.map(brand => (
                                        <li key={brand.id}>
                                            <button onClick={() => handleFilterChange('brand', slugify(brand.name))}>
                                                {brand.name} <span>{brand.p_count}</span>
                                            </button>
                                        </li>
                                    ))
                                ) : (
                                    <li>{t.noBrandsAvailable}</li>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar