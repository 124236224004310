import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import Product from './Product';
function RelatedProducts({ relatedProducts }) {
    return (
        <Swiper
            slidesPerView={4}
            spaceBetween={30}
            breakpoints={{
                320: {
                    slidesPerView: 1, // Show 1 slide
                    spaceBetween: 10, // Smaller space between slides
                },
                // When window width is >= 480px (small tablets)
                480: {
                    slidesPerView: 2, // Show 2 slides
                    spaceBetween: 15,
                },
                // When window width is >= 768px (tablets)
                768: {
                    slidesPerView: 3, // Show 3 slides
                    spaceBetween: 20,
                },
                // When window width is >= 1024px (desktops)
                1024: {
                    slidesPerView: 4, // Show 4 slides
                    spaceBetween: 30,
                },
            }}
            pagination={{
                clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
        >
            {relatedProducts && relatedProducts.map(product => (
                <SwiperSlide>
                    <Product product={product} />
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default RelatedProducts