import React, { useContext, useEffect, useState } from 'react';
import { ProductsContext } from '../context/GlobalState'; 
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import slugify from 'react-slugify';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { ContextLang } from '../context/LanguageContext';

function WishProduct({ product}) {
    const { addToCart, countCart, removeFromCart, removeFromWishlist  } = useContext(ProductsContext);
    const {t} = useContext(ContextLang);
    const [quantity, setQuantity] = useState(0);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        const basket = JSON.parse(localStorage.getItem('basket')) || [];
        const productInBasket = basket.find(item => item.sku === product.SKU);

        if (productInBasket) {
            setQuantity(productInBasket.quantity);
        } else {
            setQuantity(0);
        }
    }, [product.SKU]);

    const handleIncrease = () => {
        setIsMounted(true);
        if (quantity < 15) { 
            setQuantity(prevQuantity => prevQuantity + 1);
        } else {
            toast.warning('Maximum product count!');
        }
    };

    const handleDecrease = () => {
        setIsMounted(true);
        if (quantity > 1) {
            setQuantity(prevQuantity => prevQuantity - 1);
        } else {
            setQuantity(0); 
        }
    };

    useEffect(() => {
        if (isMounted) {
            if (quantity > 0) {
                countCart(product.SKU, quantity);
            } else {
                removeFromCart(product.SKU);
            }
        }
    }, [quantity]);

    return (
        <tr>
            <td className="tp-cart-img">
                <Link to={`/product/${slugify(product.name + '-sku-' + product.SKU)}`} target="_blank">
                    <img src={product.Foto} alt={product.name} />
                </Link>
            </td>
            <td className="tp-cart-title" title={product.name}>
                <Link to={`/product/${slugify(product.name + '-sku-' + product.SKU)}`} target="_blank">{product.name} {product.Description_EN}</Link>
            </td>
            <td className="tp-cart-price">
                <span>€{product.Price}</span>
            </td>
            <td className="tp-cart-quantity">
                <div className="tp-product-quantity mt-10 mb-10">
                    {/* Decrease Button */}
                    <span className="tp-cart-minus" onClick={handleDecrease}>
                        <svg width={10} height={2} viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>

                    {/* Quantity Input */}
                    <input
                        className="tp-cart-input"
                        type="text"
                        value={quantity}
                        onChange={(e) => setQuantity(Number(e.target.value))}
                        min="1" max="15"
                    />

                    {/* Increase Button */}
                    <span className="tp-cart-plus" onClick={handleIncrease}>
                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 1V9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1 5H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                </div>
            </td>
            <td className="tp-cart-add-to-cart">
                <button type="submit" className="tp-btn tp-btn-2 tp-btn-blue" onClick={() => addToCart(product.SKU)}>{t.AddToCart}</button>
            </td>
            <td className="tp-cart-action">
                <button className="tp-cart-action-btn" onClick={() => removeFromWishlist(product.SKU)}>
                    <BackspaceIcon />
                </button>
            </td>
        </tr>
    )
}

export default WishProduct