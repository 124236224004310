import React, { useContext } from 'react'
import Main from '../Layouts/Main'
import { Link } from 'react-router-dom'
import { ContextLang } from '../context/LanguageContext'

function NotFound() {
    const {t} = useContext(ContextLang);
    return (
        <Main>
            <section className="tp-error-area pt-110 pb-110">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8 col-md-10">
                            <div className="tp-error-content text-center">
                                <div className="tp-error-thumb">
                                    <img src="assets/img/error/error.png" alt />
                                </div>
                                <h3 className="tp-error-title mb-4">{t.ops404}</h3>
                                <Link to="/catalog" className="tp-error-btn">{t.goToShop}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Main>
    )
}

export default NotFound