import React, { createContext, useEffect, useState } from 'react';

export const ContextSite = createContext();

export const SiteProvider = ({ children }) => {
    const [openBasket, setOpenBasket] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);

    const [openQuickView, setOpenQuickView] = useState(false);
    const [quickViewProduct, setQuickViewProduct] = useState([]);
    const [openLang, setOpenLang] = useState(false);

    const [lang, setLang] = useState('en');

    useEffect(() => {
        const storedLang = localStorage.getItem('lang');
        if (storedLang) {
            setLang(storedLang);
        }
    }, []);

    const handleLangChange = (newLang) => {
        setLang(newLang);
        localStorage.setItem('lang', newLang);
        setOpenLang(false)
    };

    

    return (
        <ContextSite.Provider value={{openBasket, setOpenBasket, openMenu, setOpenMenu, openFilter, setOpenFilter, openSearch, setOpenSearch, openQuickView, setOpenQuickView, quickViewProduct, setQuickViewProduct, openLang, setOpenLang, lang, handleLangChange}}>
            {children}
        </ContextSite.Provider>
    );
};