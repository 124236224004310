import React, { useContext } from 'react'
import { ContextSite } from '../context/ContextSite';
import { Link } from 'react-router-dom';
import { ProductsContext } from '../context/GlobalState';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import DynamicFormOutlinedIcon from '@mui/icons-material/DynamicFormOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
function BottomMenu() {
    const { basket, wish } = useContext(ProductsContext);
    const { openMenu, setOpenMenu, openBasket, setOpenBasket, openSearch, setOpenSearch } = useContext(ContextSite);
    const addToCart = () => {
        setOpenBasket(!openBasket);
    };
    const openMobMenu = () => {
        setOpenMenu(!openMenu);
    }
    return (
        <div id="tp-bottom-menu-sticky" className="tp-mobile-menu d-lg-none">
            <div className="container">
                <div className="row row-cols-5">
                    <div className="col">
                        <div className="tp-mobile-item text-center">
                            <Link to="/catalog" className="tp-mobile-item-btn">
                                <DynamicFormOutlinedIcon />
                                <span>Catalog</span>
                            </Link>
                        </div>
                    </div>
                    <div className="col">
                        <div className="tp-mobile-item text-center">
                            <button className="tp-mobile-item-btn tp-search-open-btn" onClick={() => setOpenSearch(!openSearch)}>
                                <SearchOutlinedIcon />
                                <span>Search</span>
                            </button>
                        </div>
                    </div>
                    <div className="col">
                        <div className="tp-mobile-item text-center">
                            <Link to="/wishlist" className="tp-mobile-item-btn">
                                <FavoriteBorderOutlinedIcon />
                                <span>Wishlist</span>
                                {wish && wish.length > 0 && (
                                    <span className="tp-header-action-badge">{wish.length}</span>
                                )}
                            </Link>
                        </div>
                    </div>
                    <div className="col">
                        <div className="tp-mobile-item text-center">
                            <button onClick={addToCart} className="tp-mobile-item-btn">
                                <ShoppingCartOutlinedIcon />
                                <span>Cart</span>
                                {basket && basket.length > 0 && (
                                    <span className="tp-header-action-badge">{basket.length}</span>
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="col">
                        <div className="tp-mobile-item text-center">
                            <button className="tp-mobile-item-btn tp-offcanvas-open-btn" onClick={openMobMenu}>
                                <MenuOutlinedIcon />
                                <span>Menu</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BottomMenu