import React, { createContext, useContext, useEffect, useState } from 'react';
import { ContextSite } from './ContextSite';

export const ContextLang = createContext();

export const LangProvider = ({ children }) => {

    const { lang } = useContext(ContextSite);

    const translations = {
        en: {
            search: "Search for products",
            wishlist: "Wishlist",
            product: "Product",
            price: "Price",
            quantity: "Quantity",
            action: "Action",
            GoToCart: "Go To Cart",
            AddToCart: "Add To Cart",
            home: "Home",
            shoppingCart: "Shopping cart",
            viewCart: "View cart",
            checkout: "Checkout",
            subtotal: "Subtotal",
            total: "Total",
            proceedToCheckout: "Proceed to Checkout",
            updateCart: "Update Cart",
            billingDetails: "Billing Details",
            firstName: "First Name",
            lastName: "Last Name",
            countryRegion: "Country / Region",
            streetAddress: "Street address",
            houseNumberAndStreetName: "House number and street name",
            apartmentSuiteUnit: "Apartment, suite, unit, etc. (optional)",
            townCity: "Town / City",
            postcodeZIP: "Postcode ZIP",
            phone: "Phone",
            emailAddress: "Email address",
            orderNotes: "Order notes (optional)",
            notesAboutYourOrder: "Notes about your order, e.g. special notes for delivery.",
            placeOrder: "Place Order",
            yourOrder: "Your Order",
            productChangedCount: "Product changed count",
            productRemovedFromCart: "The product has been removed from the cart",
            alreadyAdded: "You have already added",
            hasBeenAddedToCart: "Has been added to the cart",
            hasBeenAddedToWishlist: "Has been added to the wishlist",
            productRemovedFromWishlist: "The product has been removed from the wishlist",
            orderAccepted: "Your order has been accepted, we will contact you as soon as possible",
            fillRequiredFields: "Fill in the required fields",
            allRightsReserved: "All Rights Reserved",
            catalog: "Catalog",
            priceFilter: "Price Filter",
            filter: "Filter",
            showing: "Showing",
            results: "results",
            categories: "Categories",
            brands: "Brands",
            addWishlist: "Add Wishlist",
            quickView: "Quick View",
            sku: "SKU",
            category: "Category",
            weight: "Weight",
            share: "Share",
            relatedProducts: "Related Products",
            trendingProducts: "Trending Products",
            bestSellers: "Best Sellers",
            newArrivals: "New Arrivals",
            yourCartIsEmpty: "Your Cart is empty",
            goToShop: "Go to Shop",
            subscribeOurNewsletter: "Subscribe our Newsletter",
            subscribe: "Subscribe",
            noCategoriesAvailable: "No categories available",
            noBrandsAvailable: "No brands available",
            ops404: "Oops! No information was found for your request."
        },
        fin: {
            product: "Tuote",
            price: "Hinta",
            quantity: "Määrä",
            action: "Toiminta",
            search: "Etsi tuotteita",
            wishlist: "Toivelista",
            GoToCart: "Ostoskoriin",
            AddToCart: "Lisää Ostoskoriin",
            home: "Koti",
            shoppingCart: "Ostoskärry",
            viewCart: "Näytä ostoskori",
            checkout: "Kassalle",
            subtotal: "Välisumma",
            total: "Yhteensä",
            proceedToCheckout: "Siirry kassalle",
            updateCart: "Päivitä ostoskori",
            billingDetails: "Laskutusosoite",
            firstName: "Etunimi",
            lastName: "Sukunimi",
            countryRegion: "Maa / Alue",
            streetAddress: "Katuosoite",
            houseNumberAndStreetName: "Talonnumerot ja kadunnimi",
            apartmentSuiteUnit: "Huoneisto, sviitti, yksikkö jne. (valinnainen)",
            townCity: "Kaupunki",
            postcodeZIP: "Postinumero ZIP",
            phone: "Puhelin",
            emailAddress: "Sähköpostiosoite",
            orderNotes: "Tilauksen muistiinpanot (valinnainen)",
            notesAboutYourOrder: "Muistiinpanot tilauksestasi, esim. erityiset muistiinpanot toimitusta varten.",
            placeOrder: "Varaa tilaus",
            yourOrder: "Tilauksesi",
            productChangedCount: "Tuotteen muutosluku",
            productRemovedFromCart: "Tuote on poistettu ostoskorista",
            alreadyAdded: "Olet jo lisännyt",
            hasBeenAddedToCart: "On lisätty ostoskoriin",
            hasBeenAddedToWishlist: "On lisätty toivelistalle",
            productRemovedFromWishlist: "Tuote on poistettu toivelistalta",
            orderAccepted: "Tilauksesi on hyväksytty, otamme sinuun yhteyttä mahdollisimman pian",
            fillRequiredFields: "Täytä vaaditut kentät",
            allRightsReserved: "Kaikki oikeudet pidätetään",
            catalog: "Luettelo",
            priceFilter: "Hintasuodatin",
            filter: "Suodata",
            showing: "Näytetään",
            results: "tuloksia",
            categories: "Kategoriat",
            brands: "Brändit",
            addWishlist: "Lisää toivelista",
            quickView: "Pikakatsaus",
            sku: "SKU",
            category: "Kategoria",
            weight: "Paino",
            share: "Jaa",
            relatedProducts: "Liittyvät tuotteet",
            trendingProducts: "Suosittelemme",
            bestSellers: "Parhaat myyjät",
            newArrivals: "Uudet saapumiset",
            yourCartIsEmpty: "Ostoskorisi on tyhjät",
            goToShop: "Mene kauppaan",
            subscribeOurNewsletter: "Tilaa uutiskirjeemme",
            subscribe: "Tilaa",
            noCategoriesAvailable: "Ei saatavilla olevia kategorioita",
            noBrandsAvailable: "Ei saatavilla olevia brändejä",
            ops404: "Hups! Pyyntösi tietoja ei löytynyt."
        },
    };
    const [t, setT] = useState(translations[lang] || translations.en);

    useEffect(() => {
        setT(translations[lang] || translations.en)
    }, [lang])


    return (
        <ContextLang.Provider value={{ t }}>
            {children}
        </ContextLang.Provider>
    );
};