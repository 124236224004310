import React, { createContext, useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import slugify from 'react-slugify';
import { toast } from 'react-toastify';
import { ContextSite } from './ContextSite';
import { ContextLang } from './LanguageContext';

export const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [randomProducts, setRandomProducts] = useState([]);
    const [parentProducts, setParnetProducts] = useState([]);
    const [catParent, setCatParent] = useState();
    const [loadingP, setLoadingP] = useState(true);
    const [loadingR, setLoadingR] = useState(true);
    const [categories, setCategories] = useState([]);
    const [catParents, setCatParents] = useState([]);
    const [brands, setBrands] = useState([]);
    const [pageCount, setPageCount] = useState();
    const [productsCount, setProductsCount] = useState();
    const [minPrice, setMinPrice] = useState();
    const [maxPrice, setMaxPrice] = useState();
    const [result, setResult] = useState();
    const [loading, setLoading] = useState(true);
    const [loadingCat, setLoadingCat] = useState(true);
    const [loadingBr, setLoadingBr] = useState(true);

    const [snackOpen, setSnackOpen] = useState(false);
    const [skus, setSkus] = useState([]);
    const [wSkus, setWSkus] = useState([]);
    const [basket, setBasket] = useState([]);
    const [localBasket, setLocalBasket] = useState([]);
    const [loadingBasket, setLoadingBasket] = useState(true);

    const [wish, setWish] = useState([]);
    const [loadingWishlist, setLoadingWishlist] = useState(true);


    const [searchParams, setSearchParams] = useSearchParams({});
    const brand = searchParams.get('brand') || 0;
    const category = searchParams.get('category') || 0;
    const parent = searchParams.get('parent') || 0;
    const page = parseInt(searchParams.get('page') || 1);
    const minP = parseInt(searchParams.get('minPrice') || 0);
    const maxP = parseInt(searchParams.get('maxPrice') || 0)
    const q = searchParams.get('q') || '';
    const {lang} = useContext(ContextSite);
    const {t} = useContext(ContextLang);
    
    
    const handleFilterChange = (filterType, value = false) => {
        let params = new URLSearchParams(searchParams.toString());

        if (filterType === 'category') {
            if (value) {
                params.set('category', value);
            } else {
                params.delete('category');
            }
            params.delete('page');
        } else if (filterType === 'brand') {
            if (value) {
                params.set('brand', value);
            } else {
                params.delete('brand');
            }
            params.delete('page');
        } else if (filterType === 'parent') {
            params.set('parent', value);
            params.delete('page');
        } else if (filterType === 'price') {
            if (value) {
                params.set('minPrice', value[0]);
                params.set('maxPrice', value[1]);
            }
            params.delete('page');
        } else if (filterType === 'minPrice' || filterType === 'maxPrice') {
            if (!value) {
                params.delete(filterType);
            }
            params.delete('page');
        }
        else if (filterType === 'q') {
            params = new URLSearchParams();
            params.set('q', slugify(value));
            let history = JSON.parse(localStorage.getItem('history')) || [];
            if (!history.some(item => item.query === value)) {
                history.push({ query: value });

                localStorage.setItem('history', JSON.stringify(history));
            }
        }
        else if (filterType === 'page') {
            params.set('page', value);
            window.scrollTo(0, 0);
        }

        setSearchParams(params);
    };

    const countCart = (sku, count) => {
        let basket = JSON.parse(localStorage.getItem('basket')) || [];

        const productExists = basket.find(product => product.sku === sku);

        if (productExists) {
            basket = basket.map(product =>
                product.sku === sku
                    ? { ...product, quantity: count }
                    : product
            );

            toast.success(t.productChangedCount);
        } else {
            addToCart(sku, count)
            return;
        }

        localStorage.setItem('basket', JSON.stringify(basket));

        setLocalBasket(basket);
    };

    const removeFromCart = (sku) => {
        let basket = JSON.parse(localStorage.getItem('basket')) || [];

        const updatedBasket = basket.filter(product => product.sku !== sku);

        localStorage.setItem('basket', JSON.stringify(updatedBasket));

        setLocalBasket(updatedBasket);

        toast.info(t.productRemovedFromCart);
    };

    const addToCart = (sku, quantity = 1) => {

        let basket = JSON.parse(localStorage.getItem('basket')) || [];
        basket && setSkus(basket.map(item => item.sku));
        const existingProduct = basket.find(product => product.sku === sku);
        if (existingProduct) {
            toast.error(t.alreadyAdded);
        } else {
            basket.push({ sku: sku, quantity: quantity });
            toast.success(t.hasBeenAddedToCart);
        }

        localStorage.setItem('basket', JSON.stringify(basket), 30 * 60 * 60 * 1000);

    };

    const wishlist = (sku) => {

        let wishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
        wishlist && setWSkus(wishlist.map(item => item.sku));
        const existingWProduct = wishlist.find(product => product.sku === sku);
        if (existingWProduct) {
            toast.error(t.alreadyAdded);
        } else {
            wishlist.push({ sku: sku });
            toast.success(t.hasBeenAddedToWishlist);
        }

        localStorage.setItem('wishlist', JSON.stringify(wishlist), 30 * 60 * 60 * 1000);

    };

    const removeFromWishlist = (sku) => {
        let wishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
        wishlist && setWSkus(wishlist.map(item => item.sku));
        const updatedWishlist = wishlist.filter(product => product.sku !== sku);

        localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));

        toast.info(t.productRemovedFromWishlist);
    };



    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true);
                const productsResponse = await fetch(`https://elnaf.fi/new/api/allProd.php?page=${page}&cat=${category}&brand=${brand}&minPrice=${minP}&maxPrice=${maxP}&q=${q}&lang=${lang}`);
                const productsData = await productsResponse.json();


                setLoadingCat(true);
                const categoriesResponse = await fetch(`https://elnaf.fi/new/api/categories.php?q=${q}&lang=${lang}`);
                const categoriesData = await categoriesResponse.json();

                const catParentsResponse = await fetch(`https://elnaf.fi/new/api/catParents.php?lang=${lang}`);
                const catParentsData = await catParentsResponse.json();

                setLoadingBr(true);
                const brandsResponse = await fetch(`https://elnaf.fi/new/api/brands.php?q=${q}`);
                const brandsData = await brandsResponse.json();


                setProducts(productsData.products);
                setLoading(false);
                setPageCount(productsData.totalPages);
                setProductsCount(productsData.totalProducts)
                setResult(productsData.result)
                setCategories(categoriesData);
                setLoadingCat(false);
                setCatParents(catParentsData);
                setBrands(brandsData);
                setLoadingBr(false);
                setMinPrice(productsData.minPrice);
                setMaxPrice(productsData.maxPrice);
            } catch (error) {
                console.error('Məlumatları yükləyərkən xəta baş verdi:', error);
            }
        };

        fetchProducts();
    }, [searchParams, lang]);

    useEffect(() => {
        const fetchProductsParent = async () => {
            try {


                setLoadingR(true);
                const productsPResponse = await fetch(`https://elnaf.fi/new/api/allProd.php?parent=${catParent}&rand=1&lang=${lang}`);
                const productsPRData = await productsPResponse.json();

                setParnetProducts(productsPRData.products)

                setLoadingP(false)

            } catch (error) {
                console.error('Məlumatları yükləyərkən xəta baş verdi:', error);
            }
        };

        fetchProductsParent();
    }, [catParent,lang]);

    useEffect(() => {
        const fetchProductsR = async () => {
            try {


                setLoadingR(true);
                const productsRResponse = await fetch(`https://elnaf.fi/new/api/randomProducts.php?lang=${lang}`);
                const productsRData = await productsRResponse.json();

                setRandomProducts(productsRData.product)
                setLoadingR(false)

            } catch (error) {
                console.error('Məlumatları yükləyərkən xəta baş verdi:', error);
            }
        };

        fetchProductsR();
    }, [lang]);

    useEffect(() => {
        const storedBasket = JSON.parse(localStorage.getItem('basket')) || [];
        setLocalBasket(storedBasket);
        const skuss = storedBasket.map(item => item.sku);

        const fetchBasketProducts = async () => {
            try {
                // setLoadingBasket(true);
                const basketProductsResponse = await fetch(`https://elnaf.fi/new/api/basket.php?skus=${skuss && skuss.join('|')}`);
                const basketProductsData = await basketProductsResponse.json();
                setBasket(basketProductsData.product);
                setLoadingBasket(false);
            } catch (error) {
                console.error('Məlumatları yükləyərkən xəta baş verdi:', error);
            }
        };

        fetchBasketProducts();
    }, [localStorage.getItem('basket')]);

    useEffect(() => {
        const storedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
        const skuss = storedWishlist.map(item => item.sku);

        const fetchWishlistProducts = async () => {
            try {
                setLoadingWishlist(true);
                const wishlistProductsResponse = await fetch(`https://elnaf.fi/new/api/basket.php?skus=${skuss && skuss.join('|')}`);
                const wishlistProductsData = await wishlistProductsResponse.json();
                setWish(wishlistProductsData.product);
                setLoadingWishlist(false);

            } catch (error) {
                console.error('Məlumatları yükləyərkən xəta baş verdi:', error);
            }
        };

        fetchWishlistProducts();
    }, [localStorage.getItem('wishlist')]);

    return (
        <ProductsContext.Provider value={{ products, setCatParent, parentProducts, randomProducts, result, categories, catParents, brands, pageCount, page, productsCount, searchParams, handleFilterChange, minPrice, maxPrice, loading, loadingCat, loadingBr, addToCart, removeFromCart, basket, loadingBasket, localBasket, wishlist, wish, loadingWishlist, removeFromWishlist, countCart }}>
            {children}
        </ProductsContext.Provider>
    );
};