import React, { useContext } from 'react'
import Main from '../Layouts/Main'
import { Link } from 'react-router-dom'
import { ProductsContext } from '../context/GlobalState';
import CardProduct from '../components/CardProduct';
import EmptyCart from '../components/EmptyCart';
import { Skeleton } from '@mui/material';
import { ContextLang } from '../context/LanguageContext';

function Cart() {
    const { basket, loadingBasket } = useContext(ProductsContext);
    const {t} = useContext(ContextLang);
    const storage = JSON.parse(localStorage.getItem('basket')) || [];
    const totalPrice = basket && basket.reduce((acc, product) => {
        const localBasket = JSON.parse(localStorage.getItem('basket')) || [];

        const productInBasket = localBasket.find(item => item.sku === product.SKU);

        const quantity = productInBasket ? productInBasket.quantity : 1;

        return acc + (product.Price || 0) * quantity;
    }, 0);
    return (
        <Main>
            <main>
                {/* breadcrumb area start */}
                {storage && storage.length > 0 && (

                    <section className="breadcrumb__area include-bg pt-95 pb-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-xxl-12">
                                    <div className="breadcrumb__content p-relative z-index-1">
                                        <h3 className="breadcrumb__title">{t.shoppingCart}</h3>
                                        <div className="breadcrumb__list">
                                            <span><Link to={'/'}>{t.home}</Link></span>
                                            <span>{t.shoppingCart}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {/* breadcrumb area end */}
                {/* cart area start */}
                {storage && storage.length > 0 ? (

                    <section className="tp-cart-area pb-120">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-9 col-lg-8">
                                    <div className="tp-cart-list mb-25 mr-30">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th colSpan={2} className="tp-cart-header-product">{t.product}</th>
                                                    <th className="tp-cart-header-price">{t.price}</th>
                                                    <th className="tp-cart-header-quantity">{t.quantity}</th>
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {loadingBasket ? (
                                                    Array.from({ length: storage.length }).map((_, index) => (
                                                        <tr>
                                                            {/* Product Image */}
                                                            <td className="tp-cart-img">
                                                                <div style={{ width: '100%', aspectRatio: 1 }}>
                                                                    <Skeleton variant="rectangular" animation="wave" width="70%" height="70%" />
                                                                </div>
                                                            </td>

                                                            {/* Product Title */}
                                                            <td className="tp-cart-title">
                                                                <Skeleton width="70%" />
                                                            </td>

                                                            {/* Product Price */}
                                                            <td className="tp-cart-price">
                                                                <Skeleton width="30%" />
                                                            </td>

                                                            {/* Product Quantity Control */}
                                                            <td className="tp-cart-quantity">
                                                                <Skeleton width="20%" />
                                                            </td>

                                                            {/* Remove Product Action */}
                                                            <td className="tp-cart-action">
                                                                <Skeleton width="10%" />
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (

                                                    basket && basket.map(product => (

                                                        <CardProduct product={product} />
                                                    ))
                                                )}


                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tp-cart-bottom">
                                        <div className="row align-items-end">
                                            <div className="col-xl-6 col-md-4">
                                                <div className="tp-cart-update ">
                                                    <Link to={'/cart'} className="tp-cart-update-btn">{t.updateCart}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6">
                                    {loadingBasket ? (
                                        <div className="tp-cart-checkout-wrapper">

                                            <div className="tp-cart-checkout-total d-flex align-items-center justify-content-between">
                                                <Skeleton width="30%" />
                                                <Skeleton width="30%" />
                                            </div>
                                            <div className="tp-cart-checkout-proceed">
                                                <Skeleton animation="wave" height="90px" />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="tp-cart-checkout-wrapper">

                                            <div className="tp-cart-checkout-total d-flex align-items-center justify-content-between">
                                                <span>{t.total}</span>
                                                <span>€{totalPrice}</span>
                                            </div>
                                            <div className="tp-cart-checkout-proceed">
                                                <Link to={"/checkout"} className="tp-cart-checkout-btn w-100">{t.proceedToCheckout}</Link>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                ) : (
                    <EmptyCart />
                )}
                {/* cart area end */}
            </main>

        </Main>
    )
}

export default Cart