import React from 'react'

function SlideSection() {
    return (
        <section className="tp-slider-area p-relative z-index-1">
            <div className="tp-slider-active tp-slider-variation swiper-container">
               <div className="slide">
                <img src="/assets/img/slider/present.png" alt="Elnaf.fi Present" />
               </div>
                
            </div>
        </section>
    )
}

export default SlideSection