import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ContextLang } from '../context/LanguageContext'

function EmptyCart() {
    const {t} = useContext(ContextLang)
    return (
        <section className="tp-error-area  pb-110">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8 col-md-10">
                        <div className="cartmini__empty text-center">
                            <img src="/assets/img/product/cartmini/empty-cart.png" alt={t.yourCartIsEmpty} />
                            <p>{t.yourCartIsEmpty}</p>
                            <Link to="/catalog" className="tp-btn">{t.goToShop}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EmptyCart