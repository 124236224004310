import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ProductsContext } from '../context/GlobalState'
import slugify from 'react-slugify';

function ProductMini({product}) {
    const {localBasket, removeFromCart} = useContext(ProductsContext);
    const quantity = localBasket.find(item => item.sku == product.SKU)?.quantity || 0;
    return (
        <div className="cartmini__widget-item">
            <div className="cartmini__thumb">
                <Link to={`/product/${slugify(product.name + '-sku-' + product.SKU)}`} target="_blank">
                    <img src={product.Foto} alt={product.name} />
                </Link>
            </div>
            <div className="cartmini__content">
                <h5 className="cartmini__title"><Link to={`/product/${slugify(product.name + '-sku-' + product.SKU)}`} target="_blank">{product.name}</Link></h5>
                <div className="cartmini__price-wrapper">
                    <span className="cartmini__price">€{product.Price} </span>
                    <span className="cartmini__quantity">x{quantity}</span>
                </div>
            </div>
            <span className="cartmini__del cursor-p" onClick={() => removeFromCart(product.SKU)}><i className="fa-regular fa-xmark" /></span>
        </div>
    )
}

export default ProductMini