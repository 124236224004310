import React, { useContext } from 'react'
import Main from '../Layouts/Main'
import { Link } from 'react-router-dom'
import Orders from '../components/Orders'
import FormOrder from '../components/FormOrder'
import { OrderContext } from '../context/OrderContext'
import { ContextLang } from '../context/LanguageContext'

function Checkout() {
    const { handleSubmitOrder } = useContext(OrderContext);
    const {t} = useContext(ContextLang);
    return (
        <Main>
            <main className='bg-gray'>
                {/* breadcrumb area start */}
                <section className="breadcrumb__area include-bg pt-95 pb-50" data-bg-color="#EFF1F5">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="breadcrumb__content p-relative z-index-1">
                                    <h3 className="breadcrumb__title">{t.checkout}</h3>
                                    <div className="breadcrumb__list">
                                        <span><Link to="/">{t.home}</Link></span>
                                        <span>{t.checkout}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* breadcrumb area end */}
                {/* checkout area start */}
                <section className="tp-checkout-area pb-120" data-bg-color="#EFF1F5">
                    <form onSubmit={handleSubmitOrder}>

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <FormOrder />
                                </div>
                                <div className="col-lg-5">
                                    {/* checkout place order */}
                                    <Orders />
                                </div>
                            </div>
                        </div>
                    </form>

                </section>
                {/* checkout area end */}
            </main>

        </Main>
    )
}

export default Checkout