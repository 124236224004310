import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import slugify from 'react-slugify';
import { ProductsContext } from '../context/GlobalState';
import { ContextSite } from '../context/ContextSite';

function MobMenu() {
    const [openIndex, setOpenIndex] = useState(null); // Hangi parent açıqdır.
    const { catParents } = useContext(ProductsContext);
    const { openLang, setOpenLang, lang, handleLangChange, openMenu, setOpenMenu } = useContext(ContextSite);
    const toggleMenu = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className={`offcanvas__area ${openMenu ? 'offcanvas-opened' : ''} `}>
            <div className="offcanvas__wrapper">
                <div className="offcanvas__close">
                    <button className="offcanvas__close-btn offcanvas-close-btn" onClick={() => setOpenMenu(false)}>
                        <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 1L1 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1 1L11 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
                <div className="offcanvas__content">
                    <div className="offcanvas__top mb-70 d-flex justify-content-between align-items-center">
                        <div className="offcanvas__logo logo">
                            <Link to="/">
                                <img src="/assets/img/logo/ElnafLogo.svg" alt="Elnaf.fi" />
                            </Link>
                        </div>
                    </div>

                    <div className="tp-main-menu-mobile fix mb-40" >
                        <nav className='tp-main-menu-content'>
                            <ul className="">
                                {catParents.map((parentCategory, index) => (
                                    <li key={parentCategory.parent_id} className={`has-dropdown ${openIndex === index ? 'dropdown-opened' : ''}`}>
                                        <a href="#" onClick={() => toggleMenu(index)}>
                                            {parentCategory.parent}
                                            <button className={`dropdown-toggle-btn ${openIndex === index ? 'dropdown-opened' : ''}`}>
                                                <i className="fa-regular fa-angle-right" />
                                            </button>
                                        </a>
                                        {openIndex === index && (
                                            <ul className="sub-menu-list">
                                                {parentCategory.cats.map((cat) => (
                                                    <li key={cat.cat_id} className="sub-category">
                                                        <Link to={`/catalog?category=${slugify(cat.category)}`} onClick={() => setOpenMenu(false)}>
                                                            {cat.category}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                    <div className="offcanvas__contact align-items-center d-none">
                        <div className="offcanvas__contact-icon mr-20">
                            <span>
                                <img src="/assets/img/icon/contact.png" alt="Elnaf.fi" />
                            </span>
                        </div>
                        <div className="offcanvas__contact-content">
                            <h3 className="offcanvas__contact-title">
                                <a href="tel:098-852-987">004524865</a>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="offcanvas__bottom">
                    <div className="offcanvas__footer d-flex align-items-center justify-content-between">
                        <div className="offcanvas__select language">
                            <div className="offcanvas__lang d-flex align-items-center justify-content-md-end">
                                <div className="offcanvas__lang-wrapper">
                                    <span className="offcanvas__lang-selected-lang tp-lang-toggle" id="tp-offcanvas-lang-toggle" onClick={() => setOpenLang(!openLang)}>
                                        {lang === 'en' ? 'English' : 'Finland'}
                                    </span>
                                    <ul className={`offcanvas__lang-list tp-lang-list ${openLang ? "tp-lang-list-open" : ''}`}>
                                        <li>
                                            <a href="#" onClick={() => handleLangChange('en')}>English</a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={() => handleLangChange('fin')}>Finland</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobMenu