import React, { useContext } from 'react'
import { OrderContext } from '../context/OrderContext'
import { ContextLang } from '../context/LanguageContext';

function FormOrder() {
    const { orderData, setOrderData } = useContext(OrderContext);
    const {t} = useContext(ContextLang)
    return (
        <div className="tp-checkout-bill-area">
            <h3 className="tp-checkout-bill-title">{t.billingDetails}</h3>
            <div className="tp-checkout-bill-form">
                
                    <div className="tp-checkout-bill-inner">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="tp-checkout-input">
                                    <label>{t.firstName} <span>*</span></label>
                                    <input
                                        type="text"
                                        placeholder={t.firstName}
                                        value={orderData.firstName}
                                        onChange={(e) => setOrderData({ ...orderData, firstName: e.target.value })}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="tp-checkout-input">
                                    <label>{t.lastName} <span>*</span></label>
                                    <input
                                        type="text"
                                        placeholder={t.lastName}
                                        value={orderData.lastName}
                                        onChange={(e) => setOrderData({ ...orderData, lastName: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="tp-checkout-input">
                                    <label>{t.countryRegion} </label>
                                    <input
                                        type="text"
                                        placeholder={t.countryRegion}
                                        value={orderData.countryRegion}
                                        onChange={(e) => setOrderData({ ...orderData, countryRegion: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="tp-checkout-input">
                                    <label>{t.streetAddress}</label>
                                    <input
                                        type="text"
                                        placeholder={t.houseNumberAndStreetName}
                                        value={orderData.streetAddress}
                                        onChange={(e) => setOrderData({ ...orderData, streetAddress: e.target.value })}
                                    />
                                </div>
                                <div className="tp-checkout-input">
                                <input
                                        type="text"
                                        placeholder={t.apartmentSuiteUnit}
                                        value={orderData.apartment}
                                        onChange={(e) => setOrderData({ ...orderData, apartment: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="tp-checkout-input">
                                    <label>{t.townCity}</label>
                                    <input
                                        type="text"
                                        value={orderData.townCity}
                                        onChange={(e) => setOrderData({ ...orderData, townCity: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="tp-checkout-input">
                                    <label>{t.postcodeZIP}</label>
                                    <input
                                        type="text"
                                        value={orderData.postCode}
                                        onChange={(e) => setOrderData({ ...orderData, postCode: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="tp-checkout-input">
                                    <label>{t.phone} <span>*</span></label>
                                    <input
                                        type="text"
                                        value={orderData.phone}
                                        onChange={(e) => setOrderData({ ...orderData, phone: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="tp-checkout-input">
                                    <label>{t.emailAddress} <span>*</span></label>
                                    <input
                                        type="text"
                                        value={orderData.email}
                                        onChange={(e) => setOrderData({ ...orderData, email: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="tp-checkout-input">
                                    <label>{t.orderNotes}</label>
                                    <textarea
                                     placeholder={t.notesAboutYourOrder}
                                     value={orderData.notes}
                                     onChange={(e) => setOrderData({ ...orderData, notes: e.target.value })}
                                     defaultValue={""} 
                                     />
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default FormOrder