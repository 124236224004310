import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { ContextLang } from './LanguageContext';
import { useNavigate } from 'react-router-dom';

export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
    const [orderData, setOrderData] = useState({
        firstName: '',
        lastName: '',
        countryRegion: '',
        streetAddress: '',
        apartment: '',
        townCity: '',
        postCode: '',
        phone: '',
        email: '',
        notes: '',
        products: [],
        shipping: '',
        total: ''
    });
    const { t } = useContext(ContextLang)
    const navigate = useNavigate();

    const handleSubmitOrder = async (e) => {
        e.preventDefault();

        // Form məlumatlarının düzgünlüyünü yoxlayın
        if (orderData.firstName.length && orderData.lastName.length && orderData.phone.length && orderData.email.length) {
            const newOrderData = { ...orderData, products: localStorage.getItem('basket') };
            try {
                const response = await axios.post('https://elnaf.fi/new/api/order.php', newOrderData);
                const result = response.data;

                switch (result.result) {
                    case 1:
                        setOrderData({
                            firstName: '',
                            lastName: '',
                            countryRegion: '',
                            streetAddress: '',
                            apartment: '',
                            townCity: '',
                            postCode: '',
                            phone: '',
                            email: '',
                            notes: '',
                            products: '',
                            shipping: '',
                            total: ''   
                        });
                        toast.success(t.orderAccepted)
                        localStorage.removeItem('basket');
                        navigate("/catalog");
                        break;
                    case 3:
                        toast.warning(t.fillRequiredFields)
                        break

                }

            } catch (error) {
                console.error("Error submitting order:", error);
            }
        } else {
            toast.warning(t.fillRequiredFields);
        }
    };

    return (
        <OrderContext.Provider value={{ orderData, setOrderData, handleSubmitOrder }}>
            {children}
        </OrderContext.Provider>
    );
};